import * as React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import Footer from '../components/footer';

const NotFoundPage = ({ location }) => {

    return (

        <Layout bg="black" location={location}>

            <Helmet>
                <title>ATUO</title>
            </Helmet>

            <div className="container">
                <div className="grid content-center py-60 md:py-80">
                    <h1 className="text-7xl md:text-9xl">Ops...</h1>
                </div>

                <div className="flex flex-col md:flex-row justify-between pb-10">

                    <div className="w-full">
                        <h3 className="text-4xl md:text-7xl mb-10 md:leading-tight">
                           Nem tudo é perfeito!
                        </h3>
                        <p className="text-2xl md:text-5xl mb-10 md:leading-normal">
                          Melhoramos continuamente a experiência com o nosso site.
                        </p>
                    </div>
                        
                </div>
            </div>

            <Footer bg="black" />

        </Layout>
    );
};

export default NotFoundPage;
